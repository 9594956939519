import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This story takes place in the year of a 500,000 A.D., planet of TRAPPIST. All humans are now immortal. Nanotechnologies and advanced medicine are used. All the diseases were cured a million years ago. All humans, even the most primitive ones, had the possibility to travel around galaxies in an instant. The wonders of cloning and bioengineering have made humanity populate over 1,000 galaxies.`}</p>
    <p>{`There were rumors about the homeworld of humanity, Rumors about it being lost or found. The clues are that it might be somewhere around the Terran System, or the triple star Centauri systems. To get there it would take a few seconds, it might be instantaneous! Due to the wonders of Planck matter. Humanity has unlocked the wonders of traveling to different universes.`}</p>
    <p>{`The first settlers were sent through. N7K429, he is a clone, he was the first one. He wants to find the homeworld of humanity. People are extremely rare, they are part of the higher society. It was the dream of his whole life. He went on many journeys. There is only one man who still lives there since the old days. He is over 450,000 years old. It is extremely hard to get to him and he isn’t willing to tell the location of the homeworld.`}</p>
    <p>{`N7K429, let’s just call him N, sets out for the adventure. N started searching for the current location of the older man (nobody knew his name). After a few minutes of searching on the inter-universal net (IUN) he found the current whereabouts of the man. He set a location for the world of Kepler 42, also known as Aurora. Not good info he thought. That was an expensive world and it was extremely hard to get into the state of the older man. He had a solution. There are humans in the world that can give you their ID card for about 20,000 IUN credits. His monthly salary was 40,000 (quite high for a clone). He bought the ID and proceeded with his plan. He had high enough clearance to get to the older man (because the ID he bought was of a friend of the older man). He got through, and now has his time. The older man was an interesting person. He liked art that was thousands of years old and, on one planet he found a transmission that was sent from the Earth in about A.D. 2000. He had a machine body with his brains and every single neuron duplicated on the robot’s circuit.`}</p>
    <p>{`He asked to talk to the older man privately. During the talk, he tried to persuade the older man about the homeworld. Later he asked some personal questions. The man hinted at the location of the homeworld. Then he figured it out and said:`}</p>
    <p>{`-”It’s Earth in the Terran system isn’t it.” (N didn’t have any feelings at that moment, but he was sure that he was right”.`}</p>
    <p>{`The man simply replied:`}</p>
    <p>{`-”Possibly, but why do you even want to know that”. And then he answered, -Because I want to know our home and become an archeologist”.`}</p>
    <p>{`-”Is that what you really want”, the old man asked.`}</p>
    <p>{`-”Yes”, N answered.`}</p>
    <p>{`-”Well then go follow the boy of your dreams”, the man said.`}</p>
    <p>{`N traveled to the planet known as Earth and started to excavate the land. That old infrastructure is hundreds of meters underground by now. So he kept excavating and over time he found an old city, about 498,000 years old. It was Kyiv and was located in a country that used to be called Ukraine. He has found many ancient artifacts, some that they called phones, headphones and even cars. It was so old and was still well preserved, how. Maybe there is someone who lived here. He was overwhelmed with emotions and started to cry. And then he saw a root with such old machinery that you he couldn’t possibly comprehend.`}</p>
    <p>{`It says “You aren’t the first one to do that brother”. N was shocked, but he doesn't have enough time to say anything before he was knocked out.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      